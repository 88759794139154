'use client';

import { ReactNode, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

interface ApplicationInsightsProviderProps {
    children: ReactNode;
    connectionString: string;
}

export function ApplicationInsightsProvider({ children, connectionString }: ApplicationInsightsProviderProps) {
    useState(() => {
        const appInsights: ApplicationInsights = new ApplicationInsights({
            config: {
                connectionString,
            },
        });
        appInsights.loadAppInsights();

        return appInsights;
    });
    return children;
}
