'use client';

import { CustomerUpdateInput } from '@shopify/hydrogen-react/storefront-api-types';
import { useMutation } from '@tanstack/react-query';

import { handleMutationUserErrors } from '../handleMutationUserErrors';

interface MutationVariables extends CustomerUpdateInput {
    language?: string;
    vatNumber?: string;
}

export function useUpdateCustomerInformationMutation({
    onSuccess,
    onError,
}: {
    onSuccess?: (success: boolean, args: MutationVariables) => void;
    onError?: () => void;
} = {}) {
    return useMutation<boolean, Error, MutationVariables>(
        ['update-customer-information'],
        async (args): Promise<boolean> => {
            const response = await fetch('/api/account/personal-info/update', {
                method: 'POST',
                body: JSON.stringify(args),
            });

            if (response.status === 200) return true;

            const body = await response.json();
            handleMutationUserErrors(response.status, body);

            if (!response.ok) throw new Error('Could not update customer information');
            return true;
        },
        {
            onSuccess,
            onError,
        },
    );
}
