'use client';

import { useMutation } from '@tanstack/react-query';

export function useVatValidationMutation({
    onSuccess,
    onError,
}: {
    onSuccess?: (isValid: boolean, vatNumber: string) => void;
    onError?: (e: Error) => void;
}) {
    return useMutation(
        ['vat-validation'],
        async (vatNumber: string): Promise<boolean> => {
            const response = await fetch('/api/vat/validate', {
                method: 'POST',
                body: JSON.stringify({ vatNumber }),
            });
            if (response.status > 499) throw new Error('Could not validate VAT number');
            if (!response.ok) return false;
            const { isValid } = await response.json();
            return isValid;
        },
        {
            onSuccess,
            onError,
        },
    );
}
