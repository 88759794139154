'use client';

import { useMemo, useState } from 'react';
import { ApolloQueryResult, FetchResult } from '@apollo/client';

import { UseShopifyRequestOptions, UseShopifyRequestProps, UseShopifyRequestResult } from './interfaces';

// let counter = false;

export function useShopifyRequest<Result>(
    fn: UseShopifyRequestProps<Result>,
    { disabled }: UseShopifyRequestOptions = {},
): UseShopifyRequestResult<Result> {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Result | undefined>();
    const [errors, setErrors] = useState<
        ApolloQueryResult<Result>['errors'] | FetchResult<Result>['errors'] | undefined
    >();

    useMemo(() => {
        if (disabled) {
            setLoading(false);
            return;
        }
        // if (counter) {
        //     // TODO: Fix double clientside requests
        //     console.log('overdrive');
        //     return;
        // }
        // counter = true;
        setLoading(true);
        fn.then(({ data, errors }) => {
            if (data) setData(data);
            if (errors) setErrors(errors);
        })
            .catch((e) => setErrors([e]))
            .finally(() => setLoading(false));
    }, [disabled, fn]);

    return { data, errors, loading };
}

export function useAwaited<Result, Response = Result>(
    promise: Promise<Response>,
    getData: (response: Response) => Promise<Result>,
) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Result | undefined>();
    const [error, setError] = useState<{ message: string; status?: number } | undefined>();

    useMemo(
        () =>
            promise
                .then(async (data) => {
                    setData(await getData(data));
                })
                .catch((e) => setError(e.message))
                .finally(() => setLoading(false)),
        [getData, promise],
    );

    return { data, error, loading };
}

export function useRequest<Result>(input: RequestInfo, init?: RequestInit) {
    const fnInput = useMemo(
        () => [fetch(input, init), async (data: Response) => data.clone().json()] as const,
        [init, input],
    );
    return useAwaited<Result, Response>(...fnInput);
}
