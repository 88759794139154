import { Path } from 'react-hook-form';

import { stepIn, TranslationFunction, yup } from '@lib/machine-parts/storefront/utils';
import { FormSanityData, AddressForm } from './interfaces';

export const addressInputFields: Path<Pick<AddressForm, 'company' | 'address1' | 'address2'>>[] = [
    'company',
    'address1',
    'address2',
];

export function getAddressSchemaResolver({
    t,
    namesRequired = false,
}: { t?: TranslationFunction<FormSanityData>; namesRequired?: boolean } = {}) {
    return yup.object().shape({
        countryCode: yup.string().required(t?.('country.required')).max(255, t?.('common.too_long')),
        ...(namesRequired
            ? {
                  firstName: yup.string().required(t?.('first_name.required')).max(255, t?.('common.too_long')),
                  lastName: yup.string().required(t?.('last_name.required')).max(255, t?.('common.too_long')),
              }
            : {}),
        company: yup.string().required(t?.('company.required')).max(255, t?.('common.too_long')),
        address1: yup.string().required(t?.('street.required')).max(255, t?.('common.too_long')),
        address2: yup.string().nullable().max(255, t?.('common.too_long')),
        zip: yup
            .string()
            .isValidZip(t ? stepIn(t, 'postalCode') : undefined)
            .required(t?.('postalCode.required')),
        city: yup.string().required(t?.('city.required')).max(255, t?.('common.too_long')),
        needsProvinceCode: yup.boolean(),
        provinceCode: yup.string().when('needsProvinceCode', {
            is: true,
            then: () => yup.string().required(t?.('provinceCode.required')),
        }),
        phone: yup.string().isPhoneFormat(t ? stepIn<FormSanityData, FormSanityData['phone']>(t, 'phone') : undefined),
    });
}
