import { CustomerUserError, CustomerErrorCode } from '@shopify/hydrogen-react/storefront-api-types';

export const handledExceptions: CustomerErrorCode[] = [
    'BAD_DOMAIN',
    'BLANK',
    'CONTAINS_HTML_TAGS',
    'CONTAINS_URL',
    'CUSTOMER_DISABLED',
    'INVALID',
    'PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE',
    'TAKEN',
    'TOO_LONG',
    'TOO_SHORT',
];

export function handleMutationUserErrors(
    status: Response['status'],
    result: { customerUserErrors: CustomerUserError[] },
) {
    if ([206, 400].includes(status)) {
        const errors = result.customerUserErrors.filter(({ code }) => code && !handledExceptions.includes(code));
        if (errors.length) {
            throw new Error(`Could not register with nonbasic error code ${errors.at(0)}`);
        }
        throw Error(
            JSON.stringify({
                errors: result.customerUserErrors.filter(
                    ({ message, field }) => Boolean(message) && Boolean(field?.length),
                ),
                partial: status === 206,
            }),
        );
    }
    return true;
}
