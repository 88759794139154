import { v4 as uuid } from 'uuid';

import {
    DEFAULT_COUNTRY,
    formatPhone,
    getCountryCallingCode,
    isShippableCountry,
    SHIPPABLE_COUNTRY_NAMES,
    ShippableCountry,
    TranslationFunction,
} from '@lib/machine-parts/storefront/utils';
import { MailingAddress, MailingAddressInput } from '@shopify/hydrogen-react/storefront-api-types';

import { AddressForm, FormSanityData, RegisterContactForm } from './interfaces';

export function addressFormToMailingAddressInput(
    address: AddressForm,
    contact?: Pick<RegisterContactForm, 'firstName' | 'lastName'>,
): MailingAddressInput & { id?: string } {
    const { firstName, lastName, needsProvinceCode, phone, countryCallingCode, ...commonFields } = address;
    const formattedPhone = formatPhone(phone, countryCallingCode);

    return {
        ...commonFields,
        firstName: contact?.firstName ?? firstName,
        lastName: contact?.lastName ?? lastName,
        phone: formattedPhone.isValid ? formattedPhone.phoneNumber : '',
        country: SHIPPABLE_COUNTRY_NAMES[commonFields.countryCode],
    };
}

export function addressToMailingAddressInput(
    address: Omit<MailingAddress, 'formatted' | 'id'> & {
        id?: string;
    },
) {
    const { __typename, id, province, provinceCode, countryCode, countryCodeV2, country, ...commonFields } = address;
    return {
        ...commonFields,
        country: country ?? countryCodeV2 ?? countryCode,
        province: province ?? provinceCode,
    };
}

export const toInputMaybeAddress = (address?: Omit<MailingAddress, 'formatted' | 'id'>) => ({
    firstName: address?.firstName ?? undefined,
    lastName: address?.lastName ?? undefined,
    phone: address?.phone ?? undefined,
    address1: address?.address1 ?? undefined,
    address2: address?.address2 ?? undefined,
    zip: address?.zip ?? undefined,
    city: address?.city ?? undefined,
    provinceCode: address?.provinceCode ?? undefined,
    company: address?.company ?? undefined,
    country: address?.country ?? undefined,
    countryCode:
        (address?.countryCodeV2 as any) ??
        (address?.countryCode as any) ??
        Object.keys(SHIPPABLE_COUNTRY_NAMES).find(
            (key) => SHIPPABLE_COUNTRY_NAMES[key as keyof typeof SHIPPABLE_COUNTRY_NAMES] === address?.country,
        ) ??
        undefined,
});

export function stubAddressToFullMailingAddress(
    address: Partial<Omit<MailingAddress, 'formatted'>> | AddressForm,
    countryCallingCode?: string,
): Omit<MailingAddress, 'formatted'> {
    const formattedPhone = formatPhone(address.phone ?? '', countryCallingCode);

    return {
        id: address.id ?? '',
        address1: address.address1 ?? '',
        address2: address.address2 ?? '',
        city: address.city ?? '',
        company: address.company ?? '',
        country: address.countryCode ? SHIPPABLE_COUNTRY_NAMES[address.countryCode as ShippableCountry] : '',
        countryCode: address.countryCode ?? '',
        firstName: address.firstName ?? '',
        lastName: address.lastName ?? '',
        phone: formattedPhone.isValid ? formattedPhone.phoneNumber : '',
        province: address.province ?? null,
        provinceCode: address.provinceCode ?? null,
        zip: address.zip ?? '',
    };
}

export function toDefaultAddressFormData({
    address,
    customer,
}: {
    address?: Partial<Omit<MailingAddress, 'formatted' | 'id'> & { id?: string }>;
    customer?: { firstName?: string; lastName?: string };
}): AddressForm {
    const phoneData = formatPhone(address?.phone ?? '');
    const countryCode = address?.countryCode ?? address?.countryCodeV2 ?? DEFAULT_COUNTRY;
    const shippableCountry = isShippableCountry(countryCode) ? (countryCode as ShippableCountry) : DEFAULT_COUNTRY;

    return {
        firstName: address?.firstName ?? customer?.firstName ?? '',
        lastName: address?.lastName ?? customer?.lastName ?? '',
        company: address?.company ?? '',
        address1: address?.address1 ?? '',
        address2: address?.address2 ?? undefined,
        zip: address?.zip ?? '',
        city: address?.city ?? '',
        provinceCode: address?.provinceCode ?? undefined,
        province: address?.province ?? undefined,
        countryCode: shippableCountry,
        countryCallingCode: phoneData.isValid ? phoneData.countryCode : getCountryCallingCode(countryCode),
        phone: phoneData.isValid ? phoneData.phoneNumber.replace(phoneData.countryCode, '') : '',
    };
}

export function parseBillingAddresses(billingAddressesJson?: string): Omit<MailingAddress, 'formatted'>[] {
    if (!billingAddressesJson) {
        return [];
    }

    try {
        const parsedBillingAddreses = JSON.parse(billingAddressesJson);
        if (Array.isArray(parsedBillingAddreses)) {
            return parsedBillingAddreses;
        }
        return [{ ...parsedBillingAddreses, id: uuid() }];
    } catch {
        return [];
    }
}

export function areEqualAddresses(
    a1: Omit<MailingAddress, 'formatted' | 'id'> & { id?: string },
    a2?: Omit<MailingAddress, 'formatted' | 'id'> & { id?: string },
): boolean {
    if (!a1 || !a2) return false;

    const f = (field?: string | null) => (field !== '' ? (field?.trim() ?? undefined) : undefined);

    const formatZipCode = (zip?: string) => zip?.replace(' ', '').toUpperCase();

    return Boolean(
        f(a1.firstName) === f(a2.firstName) &&
            f(a1.lastName) === f(a2.lastName) &&
            f(a1.company) === f(a2.company) &&
            f(a1.address1) === f(a2.address1) &&
            f(a1.address2) === f(a2.address2) &&
            formatZipCode(f(a1.zip)) === formatZipCode(f(a2.zip)) &&
            f(a1.city) === f(a2.city) &&
            f(a1.country) === f(a2.country) &&
            f(a1.phone) === f(a2.phone),
    );
}

export function getAddressMissingFields({
    address,
    t,
}: {
    address: Omit<MailingAddress, 'formatted' | 'id'> & { id?: string };
    t?: TranslationFunction<FormSanityData>;
}) {
    const missingFields = ['address1', 'zip', 'city', 'company'].filter((key) => {
        const value = address[key as keyof Omit<MailingAddress, 'formatted'>];
        return !value;
    });
    return missingFields.map((key) => {
        if (key === 'address1') {
            return t?.('street.placeholder') ?? key;
        }
        if (key === 'zip') {
            return t?.('postalCode.placeholder') ?? key;
        }
        if (key === 'countryCode') {
            return t?.('country.placeholder') ?? key;
        }
        return t?.(`${key}.placeholder` as keyof FormSanityData) ?? key;
    });
}
