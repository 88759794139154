// Use this file to export React client components (e.g. those with 'use client' directive) or other non-server utilities

export * from './lib/vat';
export * from './lib/hooks';

export {
    areEqualAddresses,
    addressFormToMailingAddressInput,
    toDefaultAddressFormData,
    stubAddressToFullMailingAddress,
    addressToMailingAddressInput,
    getAddressMissingFields,
    toInputMaybeAddress,
} from './lib/addressUtils';
export { addressInputFields, getAddressSchemaResolver } from './lib/addressSchema';
export { handleMutationUserErrors } from './lib/handleMutationUserErrors';
export { type AddressForm, type RegisterContactForm } from './lib/interfaces';
