import { Environment } from '../constants/environment';
import { getEnvValue } from './environment';

const SendgridTokenEnvKey = 'SENDGRID_TOKEN';

export interface SendgridConfig {
    fromEmail: string;
    fromName: string;
    token: string;
}

const prodSendgridConfig: Omit<SendgridConfig, 'token'> = {
    fromEmail: 'support@velroq.com',
    fromName: 'Velroq Support',
};

const testSendgridConfig: Omit<SendgridConfig, 'token'> = {
    fromEmail: 'maarten.van.den.berg@navara.nl',
    fromName: 'Developer Test',
};

export function getSendgridConfig(environment: Environment): SendgridConfig {
    switch (environment) {
        case Environment.PROD:
            return { ...prodSendgridConfig, token: getEnvValue(SendgridTokenEnvKey) };
        case Environment.TEST:
        case Environment.DEV:
            return { ...testSendgridConfig, token: getEnvValue(SendgridTokenEnvKey) };
    }
}
